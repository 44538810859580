<template>
  <b-overlay :show="processing" rounded="sm" class="overlay-display">
    <template #overlay>
      <div class="text-center">
        <b-spinner type="grow" variant="info" label="Loading..."></b-spinner>
        <p id="cancel-label">{{ processingMessage }}</p>
      </div>
    </template>
    <ErrorAlert
      :show="alert.error"
      :message="alert.message"
      @hide-alert="hideError"
    />
    <SuccessAlert
      :show="alert.success"
      :message="alert.message"
      @hide-alert="hideSuccess"
    />

    <div class="container-fluid p-0" id="order-reports">
      <div class="row">
        <div class="col-auto">
          <h3><strong>Order Adjustments</strong></h3>
        </div>
      </div>

      <div class="row mt-3 mb-0 form">
        <div class="col-md-12 col-xl-12 col-12">
          <div class="form-card">
            <b-form id="order-report-form">
              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <b-form-input
                      id="input-1"
                      v-model="order.orderId"
                      type="text"
                      placeholder="please input Order Name"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-button
                    type="button"
                    variant="primary"
                    class="submit-button"
                    @click="query"
                  >
                    Query Order
                  </b-button>
                  &nbsp;<label class="mt-1 text-danger"><b>{{ alertMessage }}</b></label>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Province: ({{ order.province }})</label>
                    <b-form-select
                      v-model="form.province"
                      :options="provincesOpt"
                      @change="selectProvince"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">City: ({{ order.city }})</label>
                    <v-select :options="citiesOpt"
                      v-model="form.city"
                      @input="selectCity"
                      class="input-height mt-1"></v-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Region: ({{ order.region }})</label>
                    <b-form-input
                      v-model="form.region"
                      type="text"
                      readonly
                      placeholder="Region"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Delivery date: ({{ order.deliveryDate }})</label>
                    <b-form-datepicker
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }"
                      v-model="form.deliveryDateNew"
                      :date-disabled-fn="dateDisabled"
                      @input="queryProducts"
                      class="mb-2"
                      required
                    ></b-form-datepicker>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Postal Code: ({{ order.postalCode }})</label>
                    <b-form-input
                      v-model="form.postalCode"
                      @blur="queryDeliveryWindow"
                      maxlength="7"
                      type="text"
                      placeholder="Please input Postal Code"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Lang: ({{ order.lang }})</label>
                    <b-form-select
                      v-model="form.lang"
                      :options="langOpt"
                      @change="queryDeliveryWindow"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">First Name: ({{ order.firstName }})</label>
                    <b-form-input
                      v-model="form.firstName"
                      type="text"
                      placeholder="please input First Name"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Last Name: ({{ order.lastName }})</label>
                    <b-form-input
                      v-model="form.lastName"
                      type="text"
                      placeholder="please input Last Name"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Address: ({{ order.address }})</label>
                    <b-form-input
                      v-model="form.address"
                      type="text"
                      placeholder="please input Address"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Buzzer Code: ({{ order.buzzerCode }})</label>
                    <b-form-input
                      v-model="form.buzzerCode"
                      type="text"
                      placeholder="please input Buzzer Code"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Address Type: ({{ order.addressType }})</label>
                    <b-form-select
                      v-model="form.addressType"
                      :options="addressTypesOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Apartment Number: ({{ order.apartmentNumber }})</label>
                    <b-form-input
                      v-model="form.apartmentNumber"
                      type="text"
                      placeholder="please input Apartment Number"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Shipping-Method: ({{ order.deliveryRate }})</label>
                    <b-form-select
                      v-model="form.shippingMethod"
                      :options="shippingMethodsOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Card Type: ({{ order.cardType }})</label>
                    <b-form-select
                      v-model="form.cardType"
                      :options="cardOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">CompleteAfter: ({{ order.completeAfter }})</label>
                    <b-form-timepicker
                      placeholder="Choose CompleteAfter"
                      id="completeAfter"
                      size="sm"
                      locale="en"
                      class="mb-2"
                      v-model="form.completeAfter"
                    ></b-form-timepicker>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">CompleteBefore: ({{ order.completeBefore }})</label>
                    <b-form-timepicker
                      placeholder="Choose CompleteBefore"
                      id="completeBefore"
                      size="sm"
                      locale="en"
                      class="mb-2"
                      v-model="form.completeBefore"
                    ></b-form-timepicker>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Bouquet Name: ({{ order.bouquetName }})</label>
                    <b-form-select
                      v-model="form.bouquetName"
                      :options="bouquetOpt"
                      @change="selectBouquet"
                      class="input-height mt-1"
                    ></b-form-select>
                    <b-form-input
                      v-model="form.bouquetName"
                      type="text"
                      placeholder="please input Bouquet Name"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Bouquet Size: ({{ order.bouquetSize }})</label>
                    <b-form-select
                      v-model="form.bouquetSize"
                      :options="bouquetSizesOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                    <b-form-input
                      v-model="form.bouquetSize"
                      type="text"
                      placeholder="please input Bouquet Size"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="row mt-3">
                <div class="w-45 col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Addons Inventory:</label>
                    <b-form-select
                      v-model="addons"
                      :options="addonsInventoryOpt"
                      :select-size="6"
                      @dblclick.native="selectAddonAdd"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="w-10">
                  <b-button-group vertical>
                    <label class="mt-1 report-label">Options</label>
                    <br />
                    <b-button
                      type="button"
                      variant="primary"
                      class="button"
                      @click="selectAddonAdd"
                    >
                      &gt;&gt;
                    </b-button>
                    <b-button
                      type="button"
                      variant="primary"
                      class="button"
                      @click="unselectAddonAdd"
                    >
                      &lt;&lt;
                    </b-button>
                  </b-button-group>
                </div>
                <div class="w-45 col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Add Addons:</label>
                    <b-form-select
                      v-model="form.addonsAdd"
                      :options="addonsAddOpt"
                      :select-size="6"
                      @dblclick.native="unselectAddonAdd"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="w-45 col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Order Addons: ({{ order.addons }})</label>
                    <b-form-select
                      v-model="form.addonsOrder"
                      :options="addonsOpt"
                      :select-size="6"
                      @dblclick.native="selectAddonRemove"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="w-10">
                  <b-button-group vertical>
                    <label class="mt-1 report-label">Options</label>
                    <br />
                    <b-button
                      type="button"
                      variant="primary"
                      class="button"
                      @click="selectAddonRemove"
                    >
                      &gt;&gt;
                    </b-button>
                    <b-button
                      type="button"
                      variant="primary"
                      class="button"
                      @click="unselectAddonRemove"
                    >
                      &lt;&lt;
                    </b-button>
                  </b-button-group>
                </div>
                <div class="w-45 col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Remove Addons:</label>
                    <b-form-select
                      v-model="form.addonsRemove"
                      :options="addonsRemoveOpt"
                      :select-size="6"
                      @dblclick.native="unselectAddonRemove"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Add Vase</label>
                    <b-form-select
                      v-model="form.addVase"
                      :options="vaseOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                    <b-form-input
                      v-model="form.addVase"
                      type="text"
                      placeholder="please input Vase"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-0 report-label">Remove Vase: ({{ order.vase }})</label>
                    <b-form-select
                      v-model="form.removeVase"
                      :options="removeVaseOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                    <b-form-input
                      v-model="form.removeVase"
                      type="text"
                      placeholder="please input Vase"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label">Delivery Notes: ({{ order.deliveryNotes }})</label>
                    <b-form-input
                      v-model="form.deliveryNotes"
                      type="text"
                      placeholder="please input Delivery Notes"
                      class="input-height mt-1"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label"
                      >Message (Maximum 800 characters)</label
                    >
                    <b-form-textarea
                      v-model="form.message"
                      rows="3"
                      max-rows="8"
                      :state="form.message.length <= 800"
                      placeholder="please input Message"
                    ></b-form-textarea>


                    <label class="mt-1 report-label"
                      >Tips: Properties and timeline tag will be added automatically.</label
                    >
                  </b-form-group>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-sm">
                  <b-form-group>
                    <label class="mt-1 report-label"
                      >Send confirmation email</label>
                    <b-form-checkbox
                      v-model="sendConfirmationEmail"
                      value="true"
                      unchecked-value="false"
                    ></b-form-checkbox>
                  </b-form-group>
                </div>
              </div>

              <b-button
                type="button"
                variant="primary"
                class="submit-button"
                @click="generateTimeline"
              >
                Generate
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
      <div class="page-break"></div>
    </div>
  </b-overlay>
</template>

<script>
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ErrorAlert from "@/components/alerts/error.vue";
import SuccessAlert from "@/components/alerts/success.vue";
import { getAllCities, getCutoffs } from "@/utils/queryUtil";

export default {
  components: {
    ErrorAlert,
    SuccessAlert,
    "v-select": vSelect,
  },
  data() {
    return {
      processing: false,
      processingMessage: "Please wait...",
      alertMessage: "",
      sendConfirmationEmail: false,
      order: {
        orderId: "",
        deliveryDate: "",
        region: "",
        tags: "",
        firstName: "",
        lastName: "",
        address: "",
        buzzerCode: "",
        postalCode: "",
        addressType: "",
        apartmentNumber: "",
        city: "",
        province: "",
        shippingMethod: "",
        deliveryNotes: "",
        message: "",
        cardType: "",
        bouquetName: "",
        bouquetSize: "",
        addons: null,
        vase: null,
        regionCode: "",
        deliveryRate: "",
      },
      form: {
        firstName: "",
        lastName: "",
        address: "",
        buzzerCode: "",
        postalCode: "",
        addressType: "",
        apartmentNumber: "",
        city: "",
        province: "",
        shippingMethod: "",
        deliveryNotes: "",
        message: "",
        cardType: "",
        bouquetName: "",
        bouquetSize: "",
        giftSetName: "",
        addonsAdd: "",
        addonsRemove: "",
        addVase: "",
        removeVase: "",
        result: "",
        lang: "en-ca",
      },
      addons: "",

      alert: {
        error: false,
        message: "",
      },

      provincesOpt: [],
      citiesOpt: [],

      shippingMethodsOpt: [
        { text: "Select an option", value: null },
      ],

      addressTypesOpt: [
        { text: "Select an option", value: null },
        { text: "House", value: "House" },
        { text: "Apartment/Complex", value: "Apartment/Complex" },
        { text: "Business", value: "Business" },
        { text: "Hospital", value: "Hospital" },
      ],

      relationshipsOpt: [
        { text: "Select an option", value: null },
        { text: "Partner", value: "Partner" },
        { text: "Friend", value: "Friend" },
        { text: "Mom", value: "Mom" },
        { text: "Grandparent", value: "Grandparent" },
        { text: "Family member", value: "Family member" },
        { text: "Colleague", value: "Colleague" },
        { text: "Client", value: "Client" },
        { text: "Myself", value: "Myself" },
        { text: "Other", value: "Other" },
      ],

      occasionsOpt: [
        { text: "Select an option", value: null },
        { text: "Birthday", value: "Birthday" },
        { text: "Anniversary", value: "Anniversary" },
        { text: "Celebration", value: "Celebration" },
        { text: "Engagement", value: "Engagement" },
        { text: "Just because", value: "Just because" },
        { text: "Sympathy", value: "Sympathy" },
        { text: "Thinking of you", value: "Thinking of you" },
        { text: "Self-care", value: "Self-care" },
        { text: "Other", value: "Other" },
      ],

      bouquetSizesOpt: [
        { text: "Select an option", value: null },
        { text: "Standard", value: "S" },
        { text: "Deluxe", value: "DX" },
        { text: "Grand", value: "GRD" },
      ],

      bouquetOpt: [
      ],
      addonsInventoryOpt: [
      ],
      addonsAddOpt: [
      ],
      addonsRemoveOpt: [
      ],
      addonsOpt: [
      ],
      vaseOpt: [
      ],
      cardOpt: [
      ],
      removeAddonsOpt: [
      ],
      removeVaseOpt: [
      ],
      bouquetSizeString: "",
      bouquetSizeObj: {},
      bouquetSizeMap: {
        "Standard": "S",
        "Deluxe": "DX",
        "Grand": "GRD",
        "Small": "S",
        "Full-Size": "DX",
        "Full-Size + Toffee ()": "DX | Utoffeea",
      },
      regionWarehouseMap: {
        "Calgary": "cg",
        "Calgary_Outskirts": "cg",
        "Edmonton": "ab",
        "Edmonton_Outskirts": "ab",
        "Halifax": "hx",
        "Hamilton": "hm",
        "Kelowna": "kl",
        "Kelowna_Surrounding": "kl",
        "Kingston": "ot",
        "Kitchener_Waterloo": "hm",
        "Langley": "bc",
        "London": "hm",
        "Maritimes": "hx",
        "Montreal": "mt",
        "Northern_AB": "ab",
        "Okanagan": "kl",
        "Ottawa": "ot",
        "Quebec_City": "mt",
        "Québec_City": "mt",
        "Really_Rural_MB": "mb",
        "Regina": "sk",
        "Rural_MB": "mb",
        "Rural_SK": "sk",
        "Saskatoon": "sk",
        "Southern_AB": "cg",
        "Surrounding_VAC": "bc",
        "Toronto": "tr",
        "Toronto_Outskirts": "tr",
        "Vancouver": "bc",
        "Vancouver_Outskirts": "bc",
        "Victoria": "bc",
        "Winnipeg": "mb",
        "Winnipeg_Outskirts": "mb",
        "Winnipeg_Surrounding": "mb",
      },
      consentOpt: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],
      langOpt: [
        { text: "CA-EN", value: "en-ca" },
        { text: "CA-FR", value: "fr-ca" },
      ],
      text2EmailLangObj: {
        "ca-en": "en-ca",
        "ca-fr": "fr-ca",
      },
      email2TextLangObj: {
        "en-ca": "ca-en",
        "fr-ca": "ca-fr",
      },
      provinceCityObj: {},
      cityRegionObj: {},
      allCitiesObj: {},
      closedDays: [],
      unavailableDates: [],
      availableDates: [],
      cutoffTime: [],
    };
  },
  async mounted() {
    this.queryCities();
  },
  methods: {
    async query() {
      if (!this.order.orderId) {
        this.displayMessage("warning", `Order Name can't be empty`);
        return false;
      }
      this.processing = true;
      this.displayMessage("Success", "Getting order data...");

      try {
        this.form.bouquetName = null;
        const orderName = this.order.orderId.replace('#', '');
        const url = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/pull-automation/order/" + orderName;
        const response = await this.axios.get(url).then(
          res => {
            console.log("response:", res);
            if (res && res.data) {
              return res.data;
            } else {
              this.displayMessage("warning", `Query Exception: Please try again or re-login.`);
            }
          }
        ).catch(err => {
          this.displayMessage("warning", `Query Exception: ${err}`);
        });
        if (!response) {
          this.processing = false;
          return;
        }
        this.order = response;

        const tags = this.order.tags || "";
        if (tags.includes("pulled") || tags.includes("sent")) {
          this.alertMessage = "This order has been pulled or sent";
          this.displayMessage("warning", `This order has been pulled or sent`);
        }
        const status = this.order.status;
        if (status) {
          if (status === "cancelled") {
            this.alertMessage = "This order has been cancelled";
            this.displayMessage("warning", `This order has been cancelled`);
          } else if (status === "closed") {
            this.alertMessage = "This order has been closed";
            this.displayMessage("warning", `This order has been closed`);
          }
        }
        const fulfillmentStatus = this.order.fulfillmentStatus;
        if (fulfillmentStatus === "fulfilled") {
          this.alertMessage = "This order has been fulfilled";
          this.displayMessage("warning", `This order has been fulfilled`);
        }

        if (this.order.province) {
          this.order.province = this.order.province.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }
        if (this.order.city) {
          this.order.city = this.order.city.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }
        await this.queryCutoffs();
        //sample Anytime (9am - 6pm)
        if (this.order.deliveryRate) {
          const rateArray = this.order.deliveryRate.split("-");
          this.order.completeAfter = rateArray[0].split("(")[1].trim();
          this.order.completeBefore = rateArray[1].split(")")[0].trim();
        }

        this.order.lang = this.text2EmailLangObj[this.order.lang?.toLowerCase()];
        const deliveryDateObj = dayjs.tz(this.order.deliveryDate,  'America/Winnipeg');
        const deliveryDateObjNew = dayjs(this.order.deliveryDate);
        const weekNum = this.extractWeekNumber(deliveryDateObjNew);
        let warehouseCode;
        if (this.order.regionCode) {
          warehouseCode = this.order.regionCode;
        } else {
          warehouseCode = this.regionWarehouseMap[this.order.region];
        }
        const postalCode = this.order.postalCode;
        const lang = this.order.lang;
        const deliveryDate = deliveryDateObj.format("YYYY-MM-DD");

        const currentTimeStamp = dayjs().tz('America/Winnipeg');
        const today = currentTimeStamp.startOf('day');
        if (!deliveryDateObj.isBefore(today)) {
          await this.queryProductsByParams(warehouseCode, weekNum, postalCode, deliveryDate, lang);
        } else {
          this.displayMessage("warning", `Order delivery date is before today. Please select a new delivery date`);
        }

        this.addonsOpt.length = 0;
        if (this.order.addons) {
          const addons = this.order.addons.split(',');
          for (const addon of addons) {
            let addonStr = addon;
            let addonQuantity = 1;
            if (addon.includes("(")) {
              addonStr = addon.split("(")[0].trim();
              addonQuantity = parseInt(addon.split("(")[1].trim().replace(/\s|[)]|x/g, ""));
            }
            const orderAddonsObj = this.addonsInventoryOpt.find(item => item.value.value === addonStr);
            if (orderAddonsObj) {
              this.addonsOpt.push({ text: `${orderAddonsObj.value.value} - $${orderAddonsObj.value.price} (x${addonQuantity})`,
                                    value: {value: orderAddonsObj.value.value, id: orderAddonsObj.value.id,
                                            price: orderAddonsObj.value.price, quantity: addonQuantity,
                                            quantityAvailable: orderAddonsObj.value.quantityAvailable,
                                            } });
            }
          }
        }
        this.removeVaseOpt.length = 0;
        this.removeVaseOpt.push({ text: "Select an option", value: null });
        if (this.order.vase) {
          this.removeVaseOpt.push({ text: this.order.vase, value: this.order.vase });
        }
      } catch(e) {
        console.error("query error:", e);
      }

      this.processing = false;

    },

    async queryCities() {
      const response = await getAllCities();
      if (response) {
        this.allCitiesObj = response;
        for (const key of Object.keys(response)) {
          const provinceName = key;
          const cities = [];
          const province = { text: provinceName, value: provinceName };
          for (const val of response[key]) {
            for (const city of val.cities) {
              cities.push(city.name);
              this.cityRegionObj[city.name] = val.regionTag;
            }
          }
          this.provincesOpt.push(province);
          this.provinceCityObj[provinceName] = cities.sort((a, b) =>
            a > b ? 1 : -1
          );
        }
        this.provincesOpt = this.provincesOpt.sort((a, b) =>
          a.value > b.value ? 1 : -1
        );
        return true;
      } else {
        this.displayMessage("warning", `queryCities Exception`);
      }
    },
    selectProvince() {
      this.form.city = "";
      this.citiesOpt = this.provinceCityObj[this.form.province];
    },
    async selectCity() {
      this.form.region = "";
      if (this.form.city) {
        this.form.region = this.cityRegionObj[this.form.city];
        await this.queryCutoffs();
        this.form.deliveryDateNew = "";
      }
    },

    async queryCutoffs() {
      const region = this.form.region || this.order.region;
      if (!region) {
        this.displayMessage("warning", `Please select region`);
        return;
      }
      const cutoffs = await getCutoffs(region);
      if (cutoffs) {
        if (cutoffs.closedDates) {
          this.closedDays = cutoffs.closedDates.map((closeD) =>
            parseInt(closeD)
          );
        }
        this.form.regionCode = cutoffs.region;
        this.unavailableDates = cutoffs.un_available;
        this.availableDates = cutoffs.available;
        this.cutoffTime = cutoffs.cutoff_time;
        return true;
      } else {
        this.displayMessage("warning", `queryCutoffs Exception`);
      }
    },

    async queryProducts() {
      const postalCode = this.form.postalCode || this.order.postalCode;
      const lang = this.form.lang || this.order.lang;
      const deliveryDate = this.form.deliveryDateNew || this.order.deliveryDate;
      console.log("new delivery date:", this.form.deliveryDateNew);
      const deliveryDateObj = dayjs(deliveryDate);
      const weekNum = this.extractWeekNumber(deliveryDateObj);
      const warehouseCode = this.form.regionCode || this.order.lang;
      console.log("lang:", lang);

      await this.queryProductsByParams(warehouseCode, weekNum, postalCode, deliveryDate, lang);
    },

    async queryProductsByParams(warehouseCode, weekNum, postalCode, deliveryDate, lang) {
      this.bouquetOpt.length = 0;
      this.bouquetOpt.push({ text: "Select an option", value: "" });
      this.bouquetSizeObj = {};
      // this.displayMessage("Success", "Getting bouquets...");
      await this.queryBouquets(warehouseCode, weekNum, "Flowers");
      // this.displayMessage("Success", "Getting Bundle...");
      await this.queryBouquets(warehouseCode, weekNum, "Bundle");
      // this.displayMessage("Success", "Getting Curated_Bundle...");
      await this.queryBouquets(warehouseCode, weekNum, "Curated_Bundle");
      this.displayMessage("Success", "Getting Products...");
      // this.displayMessage("Success", "Getting vases...");
      await this.queryVases(warehouseCode, weekNum);
      // this.displayMessage("Success", "Getting addons...");
      await this.queryAddons(warehouseCode, weekNum);
      // this.displayMessage("Success", "Getting cards...");
      await this.queryCard(warehouseCode, weekNum);

      if (postalCode && lang && deliveryDate && postalCode.length >= 6) {
        // this.displayMessage("Success", "Getting delivery window...");
        await this.queryDeliveryRates(postalCode, deliveryDate, lang);
      }
      // this.displayMessage("Success", "Query Products: Done...");
    },

    async queryDeliveryWindow() {
      const postalCode = this.form.postalCode || this.order.postalCode;
      const lang = this.form.lang || this.order.lang;
      const deliveryDate = this.form.deliveryDateNew || this.order.deliveryDate;
      console.log("postalCode:", postalCode);
      console.log("lang:", lang);
      console.log("deliveryDate:", deliveryDate);

      // this.displayMessage("Success", "Getting Delivery window...");
      // this.form.postalCode = this.form.postalCode.toUpperCase();
      await this.queryDeliveryRates(postalCode, deliveryDate, lang);
      // this.displayMessage("Success", "Query Delivery window: Done...");
    },
    async queryBouquets(warehouseCode, weekNum, productType) {
      const inventoryUrl = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/pull-automation/inventory";
      const data = {"queryId": "products_filter", "productType": productType, "region": warehouseCode, "dateCode": weekNum};
      const bouquetList = await this.axios.post(inventoryUrl, data).then(
        res => {
          console.log("Inventory response:", res.data);
          return res.data;
        }
      ).catch(err => {
        console.log("error:", err);
        this.displayMessage("warning", `queryBouquets Exception: ${err}`);
      });
      if (bouquetList) {
        const bouquetOpt = [];
        for (const bouquet of bouquetList) {
          if (bouquet.node.availableForSale) {
            bouquetOpt.push({ text: bouquet.node.title, value: bouquet.node.title });
            const variantOpt = [];
            variantOpt.push({ text: "Select an option", value: "" });
            for (const variant of bouquet.node.variants.edges) {
              const sizeText = variant.node.title;
              let sizeValue;
              if (sizeText.includes("Full-Size + Toffee")) {
                sizeValue = "DX | Utoffeea";
              } else {
                sizeValue = this.bouquetSizeMap[sizeText.split(" ")[0]];
              }
              variantOpt.push({ text: `${sizeText} - $${variant.node.price.amount} (${variant.node.quantityAvailable})`,
                                value: sizeValue,
                                id: variant.node.id,
                                sku: variant.node.sku,
                                price: variant.node.price.amount });
            }
            this.bouquetSizeObj[bouquet.node.title] = variantOpt;
          }
        }
        this.bouquetOpt.push({
          label: productType,
          options: bouquetOpt
        });
      } else {
        console.log("bouquetList is null");
      }
    },
    async queryVases(warehouseCode, weekNum) {
      const inventoryUrl = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/pull-automation/inventory";
      const vaseData = {"queryId": "products_with_product_type_filter", "productType": "Vase", "region": warehouseCode, "dateCode": weekNum};
      const vaseList = await this.axios.post(inventoryUrl, vaseData).then(
        res => {
          console.log("query vase response:", res.data);
          return res.data;
        }
      ).catch(err => {
        console.log("error:", err);
        this.displayMessage("warning", `queryVases Exception: ${err}`);
      });
      if (vaseList) {
        this.vaseOpt.length = 0;
        this.vaseOpt.push({ text: "Select an option", value: "" });
        for (const vase of vaseList) {
          if (vase.node.availableForSale) {
            const variant = vase.node.variants.edges[0].node;
            this.vaseOpt.push({ text: `${vase.node.title} - $${variant.price.amount} (${variant.quantityAvailable})`,
                                value: vase.node.title, id: variant.id, price: variant.price.amount });
          } else {
            console.log("vase is not availableForSale:", vase.node.availableForSale);
          }
        }
      } else {
        console.error("vaseList is empty:", vaseList);
      }
    },
    async queryAddons(warehouseCode, weekNum) {
      const inventoryUrl = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/pull-automation/inventory";
      const addonsData = {"queryId": "products_filter", "productType": "Gift-adds", "region": warehouseCode, "dateCode": weekNum};
      const addonsList = await this.axios.post(inventoryUrl, addonsData).then(
        res => {
          console.log("query addons response:", res.data);
          return res.data;
        }
      ).catch(err => {
        console.log("error:", err);
        this.displayMessage("warning", `queryVases Exception: ${err}`);
      });
      if (addonsList) {
        this.addonsInventoryOpt.length = 0;
        for (const addons of addonsList) {
          const variant = addons.node.variants?.edges[0]?.node;
          if (addons.node.availableForSale && variant.quantityAvailable > 0) {
            this.addonsInventoryOpt.push({ text: `${addons.node.title} - $${variant.price.amount} (${variant.quantityAvailable})`,
                                          value: {value: addons.node.title, id: variant.id, price: variant.price.amount,
                                                  quantityAvailable: variant.quantityAvailable} });
          }
        }
      }
    },
    async queryCard(warehouseCode, weekNum) {
      const inventoryUrl = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/pull-automation/inventory";
      const cardData = {"queryId": "products_with_product_type_filter", "productType": "Card", "region": warehouseCode, "dateCode": weekNum};
      const cardList = await this.axios.post(inventoryUrl, cardData).then(
        res => {
          console.log("query card response:", res.data);
          return res.data;
        }
      ).catch(err => {
        console.log("error:", err);
        this.displayMessage("warning", `queryVases Exception: ${err}`);
      });
      if (cardList) {
        this.cardOpt.length = 0;
        this.cardOpt.push({ text: "Select an option", value: "" });
        for (const card of cardList) {
          const tags = card.node.tags;
          if (card.node.availableForSale &&
              // !tags.includes("exclusive-card") &&
              (tags.includes(weekNum) || tags.includes("card-ongoing")) &&
              !tags.includes("not-" + weekNum)) {
            const variant = card.node.variants.edges[0].node;
            this.cardOpt.push({ text: `${card.node.title} - $${variant.price.amount} (${variant.quantityAvailable})`,
                                value: card.node.title, id: variant.id, price: variant.price.amount, quantityAvailable: variant.quantityAvailable });
          }
        }
      }
    },
    async queryDeliveryRates(postal, deliveryDate, lang) {
      const postalNew = postal.replace(/\s+/g, "");
      const rateUrl = `https://shop.callia.com/rates/delivery/postalcode/${postalNew}/date/${deliveryDate}?lang=${lang}`;
      const rateList = await this.axios.get(rateUrl).then(
        res => {
          console.log("queryDeliveryRates response:", res.data);
          return JSON.parse(JSON.parse(res.data.body)).sort(
            (a, b) => {
              return Number(a.zone_rate.replace("$", "")) - Number(b.zone_rate.replace("$", ""))
            }
          );
        }
      ).catch(err => {
        console.log("error:", err);
        this.displayMessage("warning", `queryDeliveryRates Exception: ${err}`);
      });
      if (rateList) {
        this.shippingMethodsOpt.length = 0;
        this.shippingMethodsOpt.push({ text: "Select an option", value: "" });
        for (const rate of rateList) {
          if (rate.status === true) {
            this.shippingMethodsOpt.push({ text: `${rate.display_text} - ${rate.zone_rate}`, value: rate.display_text });
          }
        }
        console.log("shippingMethodsOpt:", this.shippingMethodsOpt);
      }
    },
    selectBouquet() {
      this.form.bouquetSize = "";
      this.bouquetSizesOpt = this.bouquetSizeObj[this.form.bouquetName];
    },
    selectAddonAdd() {
      console.log("addons:", this.addons);
      if (this.addons) {
        const orderAddonsObj = this.addonsAddOpt.find(item => item.value.value === this.addons.value);
        if (orderAddonsObj) {
          if (orderAddonsObj.value.quantity < this.addons.quantityAvailable) {
            orderAddonsObj.value.quantity++;
            orderAddonsObj.text = `${orderAddonsObj.value.value} - $${orderAddonsObj.value.price}
                                  (x${orderAddonsObj.value.quantity})`;
          }
        } else {
          this.addonsAddOpt.push({ text: `${this.addons.value} - $${this.addons.price} (x1)`,
                                value: {value: this.addons.value, id: this.addons.id,
                                        price: this.addons.price, quantity: 1,
                                        quantityAvailable: this.addons.quantityAvailable,
                                        } });
        }
      } else {
        this.displayMessage("warning", `Please select Addons to add`);
      }
    },
    unselectAddonAdd() {
      console.log("this.form.addonsAdd:", this.form.addonsAdd);
      if (this.form.addonsAdd) {
        const orderAddonsObj = this.addonsAddOpt.find(item => item.value.value === this.form.addonsAdd.value);
        if (this.form.addonsAdd.quantity > 1) {
          this.form.addonsAdd.quantity--;
          orderAddonsObj.text = `${orderAddonsObj.value.value} - $${orderAddonsObj.value.price}
                                (x${orderAddonsObj.value.quantity})`;
        } else {
          this.addonsAddOpt = this.addonsAddOpt.filter(item => item.value.value !== this.form.addonsAdd.value);
        }
      } else {
        this.displayMessage("warning", `Please select Addons to remove`);
      }
    },
    selectAddonRemove() {
      console.log("addons:", this.form.addonsOrder);
      if (this.form.addonsOrder) {
        const orderAddonsObj = this.addonsRemoveOpt.find(item => item.value.value === this.form.addonsOrder.value);
        console.log("orderAddonsObj:", orderAddonsObj);
        if (orderAddonsObj) {
          if (orderAddonsObj.value.quantity < this.form.addonsOrder.quantity) {
            orderAddonsObj.value.quantity++;
            orderAddonsObj.text = `${orderAddonsObj.value.value} - $${orderAddonsObj.value.price}
                                  (x${orderAddonsObj.value.quantity})`;
          }
        } else {
          this.addonsRemoveOpt.push({ text: `${this.form.addonsOrder.value} - $${this.form.addonsOrder.price} (x1)`,
                                value: {value: this.form.addonsOrder.value, id: this.form.addonsOrder.id,
                                        price: this.form.addonsOrder.price, quantity: 1,
                                        quantityAvailable: this.form.addonsOrder.quantityAvailable,
                                        } });
        }
      } else {
        this.displayMessage("warning", `Please select Addons to add`);
      }
    },
    unselectAddonRemove() {
      console.log("this.form.addonsRemove:", this.form.addonsRemove);
      if (this.form.addonsRemove) {
        const orderAddonsObj = this.addonsRemoveOpt.find(item => item.value.value === this.form.addonsRemove.value);
        console.log("orderAddonsObj:", orderAddonsObj);
        if (orderAddonsObj.value.quantity > 1) {
          orderAddonsObj.value.quantity--;
          orderAddonsObj.text = `${orderAddonsObj.value.value} - $${orderAddonsObj.value.price}
                                (x${orderAddonsObj.value.quantity})`;
          console.log("orderAddonsObj2:", orderAddonsObj);
        } else {
          this.addonsRemoveOpt = this.addonsRemoveOpt.filter(item => item.value.value !== orderAddonsObj.value.value);
        }
      } else {
        this.displayMessage("warning", `Please select Addons to remove`);
      }
    },
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      const dateSelected = dayjs.tz(ymd,  'America/Winnipeg');
      const currentTimeStamp = dayjs().tz('America/Winnipeg');
      const today = currentTimeStamp.startOf('day');
      const formatedDate = dateSelected.format("YYYY-MM-DD");
      if (dateSelected.isBefore(today)) {
        return true;
      }
      if (this.availableDates && this.availableDates.length > 0) {
        for (const availableDates of this.availableDates) {
          if (availableDates.date.includes(formatedDate)) {
            const cutOffTimeStamp = dateSelected
              .subtract(parseInt(availableDates.cutoff_effect), "d")
              .startOf("d")
              .add(availableDates.cutoff_time.split(/:|\./)[0], "h")
              .add(availableDates.cutoff_time.split(/:|\./)[1], "m");

            if (cutOffTimeStamp.isAfter(currentTimeStamp)) {
              return false;
            }
          }
        }
      }
      if (this.unavailableDates.includes(formatedDate)) {
        return true;
      }
      if (this.closedDays.includes(weekday)) {
        return true;
      }

      const cutoff = this.cutoffTime
        .find((df) => +df.day === dateSelected.day());
      //Calculate the cutoff time (d - cutoff_effect)
      if (cutoff) {
        const cutOffTimeStamp = dateSelected
          .subtract(parseInt(cutoff.cutoff_effect), "d")
          .startOf("d")
          .add(cutoff.cutoff_time.split(/:|\./)[0], "h")
          .add(cutoff.cutoff_time.split(/:|\./)[1], "m");

        return cutOffTimeStamp.isBefore(currentTimeStamp);
      } else {
        console.log("no cutoff", dateSelected.day());
        return true;
      }
    },
    extractWeekNumber(date) {
      let weekNum = date.format("WW");
      // //Check if weekcode around beginning of year needs correction.
      if ((weekNum == "52" || weekNum == "53") && date.month() == 0) {
        weekNum = weekNum + date.subtract(1, "year").format("YY");
      } else if (weekNum == 1 && date.month() == 11) {
        weekNum = weekNum + date.add(1, "year").format("YY");
      } else {
        weekNum = weekNum + date.format("YY");
      }
      return weekNum;
    },
    async generateTimeline() {
      if (!this.order.orderId) {
        this.displayMessage("warning", `Order Name can't be empty`);
        return false;
      }
      if (!this.order.email) {
        this.displayMessage("warning", `Please input Order Name and Query Order`);
        return false;
      }
      const tags = this.order.tags;
      if (tags.includes("pulled") || tags.includes("sent")) {
        this.displayMessage("warning", `This order has been pulled or sent`);
        return false;
      }
      const user = this.$store.getters.currentUser;
      if (user) {
        const json = this.generateJson();
        const userEmail = user.email;
        const url = process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/pull-automation/timeline";
        const request = {
          "orderId": this.order.orderId,
          "userEmail": userEmail,
          "sendConfirmationEmail": this.sendConfirmationEmail,
          "timelines": json.timelines,
          "tags": json.tags,
          "inventories": json.inventories
        };
        this.axios.put(url, request).then(
          res => {
            console.log("generateTimeline response:", res.data);
            this.displayMessage("Success", "Properties and timeline tag have been added.");
          }
        ).catch(err => {
          console.log("generateTimeline error:", err);
          // this.displayMessage("warning", `generateTimeline Exception: ${err}`);
        });
      } else {
        this.displayMessage("warning", `Please relogin`);
      }
    },
    generateJson() {
      const json = {
        timelines: [],
        inventories: [],
        tags: []
      };
      if (this.form.message) {
        json.timelines.push({"name": "Message", "value": this.form.message});
      }
      if (this.form.firstName) {
        json.timelines.push({"name": "First Name", "value": this.form.firstName});
      }
      if (this.form.lastName) {
        json.timelines.push({"name": "Last Name", "value": this.form.lastName});
      }
      if (this.form.address) {
        json.timelines.push({"name": "Address", "value": this.form.address});
      }
      if (this.form.buzzerCode) {
        json.timelines.push({"name": "Buzzer Code", "value": this.form.buzzerCode});
      }
      if (this.form.postalCode && this.form.postalCode !== this.order.postalCode) {
        json.timelines.push({"name": "Postal Code", "value": this.form.postalCode});
      }
      if (this.form.addressType) {
        json.timelines.push({"name": "Address Type", "value": this.form.addressType});
      }
      if (this.form.apartmentNumber) {
        json.timelines.push({"name": "Apartment Number", "value": this.form.apartmentNumber});
      }
      if (this.form.city && this.form.city !== this.order.city) {
        json.timelines.push({"name": "City", "value": this.form.city});
      }
      if (this.form.province && this.form.province !== this.order.province) {
        json.timelines.push({"name": "Province", "value": this.form.province});
      }
      if (this.form.shippingMethod) {
        json.timelines.push({"name": "Shipping-Method", "value": this.form.shippingMethod});
      }
      if (this.form.completeAfter || this.form.completeBefore) {
        console.log("completeAfter:", this.form.completeAfter);
        let completeAfter;
        let completeBefore;
        let shippingMethod = "Updated Time (";
        if (this.form.completeAfter) {
          completeAfter = this.formatTime(this.form.completeAfter);
        } else {
          completeAfter = this.order.completeAfter;
        }
        shippingMethod += completeAfter;
        shippingMethod += " - ";
        if (this.form.completeBefore) {
          completeBefore = this.formatTime(this.form.completeBefore);
        } else {
          completeBefore = this.order.completeBefore;
        }
        shippingMethod += completeBefore;
        shippingMethod += ")";
        if (this.order.completeAfter !== completeAfter || this.order.completeBefore !== completeBefore) {
          json.timelines.push({"name": "Shipping-Method", "value": shippingMethod});
        }
        if (completeAfter.includes("pm") && completeBefore.includes("am")) {
          this.displayMessage("warning", `CompleteAfter should before CompleteBefore`);
        } else if (completeAfter.includes("am") && completeBefore.includes("pm")) {
          console.log("CompleteAfter and CompleteBefore OK");
        } else {
          let completeAfterObj;
          if (completeAfter.includes(":")) {
            completeAfterObj = dayjs(completeAfter, 'h:mma');
          } else {
            completeAfterObj = dayjs(completeAfter, 'ha');
          }
          let completeBeforeObj;
          if (completeBefore.includes(":")) {
            completeBeforeObj = dayjs(completeBefore, 'h:mma');
          } else {
            completeBeforeObj = dayjs(completeBefore, 'ha');
          }
          if (completeAfterObj.isAfter(completeBeforeObj)) {
            this.displayMessage("warning", `CompleteAfter should before CompleteBefore`);
          }
        }
      }
      if (this.form.deliveryNotes) {
        json.timelines.push({"name": "Delivery Notes", "value": this.form.deliveryNotes});
      }
      if (this.form.cardType) {
        json.timelines.push({"name": "Card Type", "value": this.form.cardType});
      }
      if (this.form.bouquetName) {
        let bouquetSize;
        if (this.form.bouquetSize) {
          bouquetSize = this.form.bouquetSize;
        } else {
          bouquetSize = "S";
        }
        json.timelines.push({"name": "bouquet", "value": this.form.bouquetName + " - " + bouquetSize});
      }
      if (this.form.giftSetName) {
        json.timelines.push({"name": "giftset", "value": this.form.giftSetName});
      }

      if (this.addonsAddOpt && this.addonsAddOpt.length > 0) {
        const addonsAdd = [];
        for (const addon of this.addonsAddOpt) {
          if (addon && addon.value) {
            const addonStr = `${addon.value.value} (x${addon.value.quantity})`;
            addonsAdd.push(addonStr);
            json.inventories.push({"name": addon.value.value, "type": "-",
                                  "id": addon.value.id, "quantity": addon.value.quantity});
          }
        }
        if (addonsAdd.length > 0) {
          json.timelines.push({"name": "addons added", "value": addonsAdd.join(",")});
        }
      }

      if (this.addonsRemoveOpt && this.addonsRemoveOpt.length > 0) {
        const addonsRemove = [];
        for (const addon of this.addonsRemoveOpt) {
          if (addon && addon.value) {
            const addonStr = `${addon.value.value} (x${addon.value.quantity})`;
            addonsRemove.push(addonStr);
            json.inventories.push({"name": addon.value.value, "type": "+",
                                  "id": addon.value.id, "quantity": addon.value.quantity});
          }
        }
        if (addonsRemove.length > 0) {
          json.timelines.push({"name": "addons removed", "value": addonsRemove.join(",")});
        }
      }
      if (this.form.addVase) {
        json.timelines.push({"name": "vase added", "value": this.form.addVase});
      }
      if (this.form.removeVase) {
        json.timelines.push({"name": "vase removed", "value": this.form.removeVase});
      }

      if (this.form.deliveryDateNew) {
        console.log("new delivery date:", this.form.deliveryDateNew);
        console.log("old delivery date:", this.order.deliveryDate);
        const deliveryDateObj = dayjs.tz(this.form.deliveryDateNew,  'America/Winnipeg');
        const deliveryDate = deliveryDateObj.format("MM/DD/YYYY");
        if (deliveryDate !== this.order.deliveryDate) {
          json.tags.push(deliveryDate);
        }
      }
      if (this.form.region) {
        if (this.form.region !== this.order.region) {
          json.tags.push(`Region:${this.form.region}`);
        }
      }
      if (this.form.phone) {
        if (this.form.phone !== this.order.phone) {
          json.tags.push(`Phone:${this.form.phone}`);
        }
      }

      this.form.result = json;
      return json;
    },
    formatTime(time) {
      const timeObj = dayjs(time, 'HH:mm:ss');
      const timeArray = time.split(":");
      if (timeArray[1] === "00") {
        return timeObj.format("ha");
      } else {
        return timeObj.format("h:mma");
      }
    },
    hideError() {
      this.alert.error = false;
    },
    hideSuccess() {
      this.alert.success = false;
    },
    displayMessage(messageType, messageBody) {
      this.$bvToast.toast("Empty Body", {
        title: messageBody,
        variant: messageType,
        headerClass: "py-2 px-4",
        bodyClass: "d-none",
        autoHideDelay: 5000,
        solid: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/form.scss";

.card {
  width: 100%;
  border: none;
}

.report-label {
  font-weight: 600;

  .weight {
    font-weight: 400;
  }

  span {
    font-weight: 400;
  }
}

.upper {
  text-transform: case;
}

#checkbox-group,
.custom-select a:hover,
a:active,
a:focus {
  outline: 0;
}

.page-break {
  width: 100%;
  height: 52px;
}
</style>
