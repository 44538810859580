// eslint-disable-next-line @typescript-eslint/no-var-requires
const axios = require("axios");

const BUTTER_TOKEN = "5e806242bbdfecb564c3c9f0a5d775cc294193b1";

const getFromButter = async (keys) => {
  const url = `https://api.buttercms.com/v2/content/?keys=${keys}&locale=en-ca&timeout=10000&auth_token=${BUTTER_TOKEN}`;
  const response = await axios.get(url).then(
    res => {
      console.log("getOccasions response:", res.data);
      return res.data;
    }
  ).catch(err => {
    console.log("getOccasions error:", err);
  });
  return response;
};

const getOccasions = async () => {
  const KEYS = "occasions";
  return await getFromButter(KEYS);
}

const getAllCities = async () => {
  const url = `https://shop.callia.com/landingpage/all`;
  const response = await axios.get(url, {
      headers: { "callia-lang": "en-ca" },
    }).then(
    res => {
      console.log("cities response:", res.data);
      return res.data;
    }
  ).catch(err => {
    console.log("error:", err);
  });
  return response;
};

const getCutoffs = async (region) => {
  const url = `https://shop.callia.com/v3/region/cutoff/${region}`;
  const response = await axios.get(url).then(
    res => {
      console.log("cutoff response:", res.data);
      return res.data;
    }
  ).catch(err => {
    console.log("error:", err);
  });
  return response;
};


module.exports = {
  getAllCities,
  getCutoffs,
  getOccasions,
};