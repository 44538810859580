<template>
  <div class="container-fluid p-0 h-100">
    <div class="container-fluid p-0">
      <b-alert
        :show="notification1.showErrorCountDown"
        dismissible
        fade
        :variant="notification1.type"
      >
        {{ notificationMessage1 }}
      </b-alert>
      <h3><strong>Unsubscribe Phone (Opt Out)</strong></h3>
      <div class="row">
        <div class="col-12 col-xl-6">
          <b-overlay :show="processing1" rounded="sm" class="overlay-display">
            <template #overlay>
              <div class="text-center">
                <b-spinner
                  type="grow"
                  variant="info"
                  label="Loading..."
                ></b-spinner>
                <p id="cancel-label">Please wait...</p>
              </div>
            </template>
            <div class="card">
              <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <div class="card-body">
                  <div class="form-group">
                    <label class="form-label"
                      >Phone (Format: 2045551111,2045552222) <strong class="required-star">*</strong></label
                    >
                    <b-form-textarea
                      v-model="form.phone"
                      rows="3"
                      max-rows="8"
                      placeholder="Please input phone"
                    ></b-form-textarea>
                  </div>
                  <div class="form-group">
                    <label class="mt-1 report-label">Opt Out Type:</label>
                    <b-form-select
                      v-model="form.optoutType"
                      :options="optoutTypeOpt"
                      class="input-height mt-1"
                    ></b-form-select>
                  </div>
                  <div class="form-group">
                    <label class="form-label"
                      >Result:
                      <strong class="required-star">{{ result }}</strong></label
                    >
                  </div>

                  <b-button
                    type="submit"
                    variant="primary"
                    class="action-btn form-submit-button"
                    >Submit</b-button
                  >
                  &nbsp;
                  <b-button class="action-btn" type="reset" variant="danger"
                    >Reset</b-button
                  >
                </div>
              </b-form>
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
    <br />
    <h3><strong>Unsubscribed phone List</strong></h3>
    <div class="row mt-6 col-xs-12 col-sm-8 col-md-10">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
      <div class="col-xs-2 col-sm-2">
        <b-form-input
          id="filter-input"
          v-model="filter"
          type="search"
          placeholder="Filter..."
        ></b-form-input>
      </div>
      <div class="col-xs-2 col-sm-2">
        <b-button variant="primary" @click="query">
          Refresh
        </b-button>
      </div>
    </div>
    <div>
      <b-alert
        v-model="notification.showErrorCountDown"
        dismissible
        fade
        :variant="notification.type"
      >
        {{ notificationMessage }}
      </b-alert>
      <b-overlay :show="processing" rounded="sm" class="overlay-display">
        <b-table
          hover
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          :filter-included-fields="filterOn"
          :filter="filter"
          @filtered="onFiltered"
          show-empty
          small
        >
        </b-table>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

export default {
  data: function() {
    return {
      perPage: 10,
      currentPage: 1,
      phone: "",
      processing: false,
      processingMessage: "Please wait...",
      fields: [
        {
          key: "phone",
          sortable: true,
          label: "Phone"
        },
        {
          key: "expired",
          sortable: true,
          label: "Expired At"
        }
      ],
      items: [],
      notification: {
        showErrorCountDown: 0,
        type: "warning"
      },
      notificationMessage: "",
      form: {
        phone: "",
        optoutType: "1"
      },
      optoutTypeOpt: [
        { text: "1 Week", value: "1" },
        { text: "2 Weeks", value: "2" },
        { text: "1 Month", value: "4" },
        { text: "For Ever", value: "10" },
      ],
      filter: null,
      filterOn: ["phone"],
      result: "",
      show: true,
      processing1: false,
      notification1: {
        showErrorCountDown: 0,
        type: "warning"
      },
      notificationMessage1: ""
    };
  },
  async created() {
    this.query();
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  methods: {
    async query() {
      this.processing = true;
      await this.axios
        .get(process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/unsubscribe", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.idToken
          }
        })
        .then(response => {
          this.items = response.data;
          if (this.items && this.items.length > 0) {
            for (const item of this.items) {
              if (item.timetolive) {
                item.expired = dayjs.unix(item.timetolive).format();
              } else {
                item.expired = "Never";
              }
            }
          }
          this.processing = false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async onSubmit(event) {
      this.processing = true;
      event.preventDefault();
      try {
        await this.axios
          .post(
            process.env.VUE_APP_API_ADMIN_API_ENDPOINT + "/unsubscribe",
            {
              phone: this.form.phone,
              optoutType: this.form.optoutType
            }
          )
          .then(response => {
            if (response && response.data) {
              if (response.data.msg) {
                this.result = response.data.msg;
              } else {
                this.result = "Success. It has been added to opt-out: " + this.form.phone;
              }
            } else {
              this.result = "Failed. Please log out and log in again.";
            }
          });
        this.query();
        this.processing = false;
        this.onReset();
      } catch (error) {
        this.processing = false;
        console.log(error);
        this.notificationMessage = "Oopps, Something is not right !!!";
        this.notification = {
          showErrorCountDown: 5,
          type: "danger"
        };
      }
    },
    onReset(event) {
      if (event) {
        event.preventDefault();
      }
      this.form.phone = "";
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  }
};
</script>

<style>
.action-btn {
  background-color: #17a2b8;
  border: none;
}
.grid-main {
  height: 800px;
}
</style>
